<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" class="el-icon-plus" size="small" @click="add">添加服务商</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="服务商姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入服务商姓名"></el-input>
      </el-form-item>
      <el-form-item label="服务商电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入服务商电话"></el-input>
      </el-form-item>
      <el-form-item label="公司名称：">
        <el-input size="small" v-model="searchForm.company_name" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="清退" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="服务商姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="服务商电话" align="center"></el-table-column>
      <el-table-column prop="company_name" label="公司名称" align="center"></el-table-column>
      <el-table-column prop="business_license" label="营业执照" align="center" width="200">
        <template v-slot="{ row }">
          <el-image style="width: 140px; height: 70px" :src="row.business_license" :preview-src-list="[row.business_license]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="commission_ratio" label="服务商佣金比例" align="center">
        <template v-slot="{ row }">{{ row.commission_ratio }}%</template>
      </el-table-column>
      <el-table-column prop="total_qrcode" label="总计二维码数" align="center"></el-table-column>
      <!-- <el-table-column prop="business_license" label="推广团长数" align="center"></el-table-column> -->
      <!-- <el-table-column prop="business_license" label="推广商家数" align="center"></el-table-column> -->
      <el-table-column prop="total_amount" label="总收益" align="center">
        <template v-slot="{ row }">￥{{ row.commission_ratio }}</template>
      </el-table-column>
      <el-table-column prop="amount" label="当前余额" align="center">
        <template v-slot="{ row }">￥{{ row.commission_ratio }}</template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" label="累计提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.commission_ratio }}</template>
      </el-table-column>
      <el-table-column prop="act_num" label="已激活二维码数" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 1 ? '正常' : '清退' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="operation(row, 1)" type="text" size="small">编辑</el-button>
          <el-button @click="operation(row, 2)" type="text" size="small" v-if="row.status == 1">清退</el-button>
          <el-button @click="operation(row, 3)" type="text" size="small">查看明细</el-button>
          <el-button @click="operation(row, 4)" type="text" size="small">下载二维码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog :title="addForm.id ? '编辑' : '添加'" :visible.sync="showAdd" width="600px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
        <el-form-item label="服务商姓名：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="服务商电话：" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="公司名称：" prop="company_name">
          <el-input v-model="addForm.company_name"></el-input>
        </el-form-item>
        <el-form-item label="服务商佣金比例" prop="commission_ratio">
          <el-input v-model="addForm.commission_ratio">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="增加二维码数：" prop="total_qrcode">
          <el-input v-model="addForm.total_qrcode"></el-input>
        </el-form-item>
        <el-form-item label="营业执照：" prop="business_license">
          <ReadyUploadSource
            :showStyle="{
              width: '150px',
              height: '100px',
            }"
            @getSource="val => (addForm.business_license = val.path)"
            :path="addForm.business_license"
            @removeThis="() => (addForm.business_license = '')"
          ></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看明细" :visible.sync="showDetailed" width="780px">
      <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="生成时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收益支出" align="center">
          <template v-slot="{ row }">
            {{ row.type == 1 ? 'wifi' : row.type == 2 ? '第三方活动' : row.type == 3 ? '扫码点餐' : '提现' }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="预估收益" align="center">
          <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        company_name: '',
        start_time: '',
        end_time: '',
        status: '',
      },
      addForm: {
        name: '',
        mobile: '',
        company_name: '',
        business_license: '',
        commission_ratio: '',
        total_qrcode: '',
      },
      rules: {
        name: { required: true, message: '请填写服务商姓名', trigger: 'change' },
        mobile: { required: true, message: '请填写服务商电话', trigger: 'blur' },
        company_name: { required: true, message: '请填写公司名称', trigger: 'blur' },
        business_license: { required: true, message: '请添加营业执照', trigger: 'change' },
        commission_ratio: { required: true, message: '请填写服务商佣金比例', trigger: 'blur' },
        total_qrcode: { required: true, message: '请填写增加二维码数', trigger: 'blur' },
      },
      id: '',
      list: [],
      balanceList: [],
      total: 0,
      showAdd: !1,
      showDetailed: !1,
      balance_page: 1,
      balance_rows: 10,
      balance_total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    balanceUpdateData(val, status) {
      if (status == 0) {
        this.balance_rows = val;
      } else {
        this.balance_page = val;
      }
      this.getBalanceList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.serviceProviderList, searchForm).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    add() {
      this.addForm = {
        name: '',
        mobile: '',
        company_name: '',
        business_license: '',
        commission_ratio: '',
        total_qrcode: '',
      };
      this.showAdd = !0;
    },
    comfirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.serviceProvider.editServiceProvider : this.$api.serviceProvider.addServiceProvider, this.addForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
              this.getList();
              this.showAdd = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getBalanceList() {
      this.$axios
        .post(this.$api.serviceProvider.BalanceLog, {
          page: this.balance_page,
          rows: this.balance_rows,
          identity_type: 1,
          facilitator_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showDetailed = !0;
            this.balanceList = res.result.list;
            this.balance_total = res.result.total_number;
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    operation(row, type) {
      if (type == 1) {
        this.addForm.id = row.id;
        this.addForm.name = row.name;
        this.addForm.mobile = row.mobile;
        this.addForm.company_name = row.company_name;
        this.addForm.business_license = row.business_license;
        this.addForm.commission_ratio = row.commission_ratio;
        this.addForm.total_qrcode = row.total_qrcode;
        this.showAdd = !0;
      }
      if (type == 2) {
        this.$confirm('请确认是否清退该服务商？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$axios
            .post(this.$api.serviceProvider.serviceProviderCheck, {
              id: row.id,
              status: -1,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message.success('清退成功');
                row.status = -1;
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      }
      if (type == 3) {
        this.id = row.id;
        this.balance_page = 1;
        this.getBalanceList();
      }
      if (type == 4) {
        this.$axios
          .post(this.$api.serviceProvider.createZip, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              let a = document.createElement('a');
              a.href = res.result;
              a.id = 'download';
              document.body.appendChild(a);
              a.click();
              let aDom = document.getElementById('download');
              document.body.removeChild(aDom);
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
